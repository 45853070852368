"use strict";

import api from "@/api/api";
import userService from "../../services/user.service";
import { CONSTANTS, getErrorMessage } from "../../global/index";
const {
  SET_TOKEN,
  SET_IDENTITY,
  DOWNLOADING,
  DOWNLOAD_SUCCESS,
  ERROR_MSG,
  ERROR_STATUS,
} = CONSTANTS;
const ERROR_OBJ = {
  success: false,
  status: ERROR_STATUS,
  code: 500,
  message: ERROR_MSG,
  data: null
};

export default {
  namespaced: true,
  state: {
    token: "",
    identity: {},
    dText: "Descargar Reporte.CSV",
    dflag: false,
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getIdentity(state) {
      return state.identity;
    },
    downloadFlag(state) {
      return state.dflag;
    },
    downloadText(state) {
      return state.dText;
    },
  },
  mutations: {
    [SET_TOKEN](state, payload) {
      state.token = payload;
    },
    [SET_IDENTITY](state, payload) {
      state.identity = payload;
    },
    [DOWNLOADING](state, change) {
      state.dflag = false;
      state.dText = change;
    },
    [DOWNLOAD_SUCCESS](state, change) {
      state.dflag = true;
      state.dText = change;
    },
  },
  actions: {
    async login({ commit }, payload) {
      try {
        const headers = { "Content-Type": "application/json" };
        const response = await api.login(payload, headers);
        localStorage.removeItem("tkn");
        localStorage.removeItem("lastv");
        localStorage.removeItem("ath");
        
        if (response.data.success) {
          commit(SET_TOKEN, response.data.data.token);
          localStorage.setItem("tkn", response.data.data.token);
        }

        return response.data;
      } catch (error) {
        console.log(error)
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async getData({ commit }) {
      try {
        const response = await userService.getData();
        if (response.success) {
          commit(SET_IDENTITY, response.data);
        }

        return response;
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async resendCode({ commit }, payload) {
      try {
        const headers = {
          "Content-Type": "application/json",
          authorization: payload.item.token
        };
        const response = await api.resendCode(headers);
        return response;
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async resendPass({ commit }, payload) {
      try {
        const headers = { "Content-Type": "application/json" };
        const response = await api.resendPass(payload, headers);
        return response;
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async contact({ commit }, payload) {
      try {
        const headers = { "Content-Type": "application/json" };
        const response = await api.addItem(payload, headers);

        return response.data;
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    logout({ commit }) {
      localStorage.removeItem("tkn");
      localStorage.removeItem("lastv");
      localStorage.removeItem("ath");
      commit(SET_IDENTITY, null);
      commit(SET_TOKEN, null);
    },
  },
};
